.footer {
  height: 331px;
  background: #0f1621;
  color: #fff;
}

.content {
  width: 1200px;
  margin: 0 auto;
  border: 1px solid transparent;
}

.row {
  display: flex;
  align-items: flex-end;
  height: 220px;
  padding-bottom: 40px;
  box-sizing: border-box;
  border-bottom: 1px solid #333;
}

.col {
  &:nth-child(2),
  &:nth-child(3) {
    padding-left: 84px;
  }

  &:last-child {
    text-align: right;
    margin-left: auto;
  }
}

.textTitle {
  font-size: 16px;
  font-family: "HiraginoSansGB-W6", HiraginoSansGB;
  margin-top: 27px;
}

.subText {
  font-size: 12px;
  font-family: "HiraginoSansGB-W3", HiraginoSansGB;
  margin-top: 22px;
}

.qrcode {
  width: 100px;
  display: inline-block;
}

.copyRightWrapper {
  font-size: 12px;
  font-family: "HiraginoSansGB-W3", HiraginoSansGB;
  color: #999;
  text-align: center;
  margin-top: 40px;
}

.link {
  color: #999;
  text-decoration: none;

  &:hover {
    color: var(--semi-color-primary);
  }
}
