.avatarContainer {
  display: flex;
  align-items: center;
}

.right {
  padding-left: 12px;
}

.right .email {
  font-size: 12px;
  color: var(--semi-color-text-2);
}

.signout {
  text-align: center;
  color: red;
  width: 100%;
}

.headTitle {
  composes: enHeader3Bold from "@scss/global.scss";
  color: var(--semi-color-text-0);
  cursor: pointer;
}

.iconBtn:global(.semi-button-borderless) {
  color: var(--semi-color-text-1) !important;
  font-size: 16px;
  box-shadow: none;
}

.btnWrapper {
  position: relative;
}

.redDot {
  position: absolute;
  left: 8px;
  top: 6px;
  background-color: red;
  width: 6px;
  height: 6px;
  display: block;
  font-size: 0;
  border-radius: 50%;
}

:global(.semi-dropdown[style="font-weight: normal;"] .semi-navigation-item) {
  border-radius: 0;
  padding: 8px 16px;
}

.dropdownContainer {
  :global(a) {
    text-decoration: none;
    color: inherit;
    display: block;
    width: 100%;
  }
}
